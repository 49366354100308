import type { DropdownMenuItemDefinition } from "@pollen/ui-library";

import type { RouteLocationRaw } from "#vue-router";
import type { I18nMessage } from "~/core/i18n/i18n.model";

/* To be deleted when new navigation will be activated */
export type MainHeaderNavigationItem = {
  label: I18nMessage;
  to: RouteLocationRaw;
};

export function useMainHeaderNavigation() {
  const isEventPageEnabled = useFeatureFlag("events");

  const {
    public: {
      apps: { jobs: jobsUrl },
    },
  } = useRuntimeConfig();

  const { t } = useI18n();

  const router = useRouter();
  const route = useRoute();

  function groupIsActive(items: DropdownMenuItemDefinition[]) {
    return items
      .map((item) =>
        !item.divider && !item.group && item.to
          ? router.resolve(item.to).path
          : undefined,
      )
      .filter(Boolean)
      .includes(route.path);
  }

  const items = computed<DropdownMenuItemDefinition[]>(() => {
    const resourcesSubNavigation: DropdownMenuItemDefinition[] = [
      ...(isEventPageEnabled
        ? ([
            {
              label: "Évènements",
              to: { name: "events" },
            },
          ] as const)
        : []),
      {
        label: "Blog",
        to: { name: "blog" },
      },
      {
        label: "Devenir trainer",
        to: { name: "become-trainer" },
      },
      {
        label: "Méthodologie",
        to: { name: "methodology" },
      },
      {
        label: "Financement",
        to: { name: "getting-financed" },
      },
      {
        label: "Qui sommes-nous ?",
        to: { name: "about-us" },
      },
      {
        label: "Nous rejoindre",
        href: jobsUrl,
      },
    ];

    return [
      {
        label: t("catalog.navigation_label"),
        to: { name: "catalog" },
      },
      {
        label: "Pollen Academy",
        to: { name: "academy" },
      },
      {
        label: "Offre entreprises",
        to: { name: "enterprise" },
      },
      {
        group: true,
        title: "Ressources",
        items: resourcesSubNavigation,
        selected: groupIsActive(resourcesSubNavigation),
      },
    ];
  });

  return { items };
}
